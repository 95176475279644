import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'ypo-robot',
  templateUrl: './robot.component.html',
  styleUrls: ['./robot.component.scss']
})
export class RobotComponent implements OnInit, OnDestroy {

  constructor(@Inject(DOCUMENT) private _document) { }
  ngOnInit() {
    this._document.body.style.background = '#fff';
  }
  ngOnDestroy() {
    this._document.body.style.background = '#000';
  }
}
