export enum ENUMLOADING {
  NOTSETYET,
  CHOOSE_LANG,
  CHOOSE_ROBOT,
  UNKNOWN_TOKEN,
  INVALID_TOKEN,
  SOON,
  TOO_SOON,
  LOADING,
  LIVE,
  TOO_LATE
}

export enum ENUMUSER {
  WAIT,
  DEVICE_VISIBLE,
  DEVICE_HIDDEN,
  PORTRAIT_MODE,
  ROBOT_CHANGE,
  UNKNOWN_DEVICE,
  THANKS,
  NOTSETYET
}
